import React from "react";

import {
  useParams,
  useUserDictionaryId,
  Card,
  Skeleton,
  Toast,
  // @ts-ignore
} from "@projectdiction/common-react";

// @ts-ignore
import { useTranslation } from "react-i18next";

import ReadingItemTextCard from "./ReadingItemTextCard";

import { PDDictionaryContext } from "../../../contexts/PDDictionaryContext";

export default function ReadingListViewItem() {
  const [dictionaryId, setDictionaryId] = React.useState<string>();
  const [shouldUseUserDictionary, setShouldUseUserDictionary] =
    React.useState<boolean>(false);
  const [showDictionary, setShowDictionary] = React.useState<boolean>(false);

  const params = useParams();

  const { t } = useTranslation();

  const [viewUpdater, setViewUpdater] = React.useState<boolean>();

  const toast = React.useRef<Toast>();

  const updateView = () => {
    setViewUpdater(!viewUpdater);
  };

  const { userDictionaryId, isLoading: userDictionaryIdLoading } =
    useUserDictionaryId();

  return (
    <PDDictionaryContext.Provider
      value={{
        dictionaryId,
        setDictionaryId,
        userDictionaryId,
        userDictionaryIdLoading,
        shouldUseUserDictionary,
        setShouldUseUserDictionary,
        showDictionary,
        setShowDictionary,
      }}
    >
      <Card
        title={t("reading.lists.titles.reading_list_copy")}
        pt={{
          root: { className: "w-full h-full" },
          body: { className: "w-full h-full p-0" },
          title: { className: "w-full flex justify-content-center" },
          subtitle: { className: "w-full flex justify-content-center" },
          content: {
            className: "w-full p-0",
            style: { height: "calc(100% - 4rem)" },
          },
        }}
      >
        <ReadingItemTextCard
          readingItemId={params.id}
          updateParentView={updateView}
          toast={toast}
        />
      </Card>
      <Toast ref={toast} />
    </PDDictionaryContext.Provider>
  );
}
