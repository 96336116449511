import React from "react";

export const PDDictionaryContext = React.createContext({
  dictionaryId: "",
  setDictionaryId: (dictionaryId: string) => {},
  userDictionaryId: "",
  userDictionaryIdLoading: true,
  shouldUseUserDictionary: false,
  setShouldUseUserDictionary: (shouldUseUserDictionary: boolean) => {},
  showDictionary: true,
  setShowDictionary: (showDictionary: boolean) => {},
});
