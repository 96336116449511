// @ts-ignore
import { I18nextProvider } from "react-i18next";
// @ts-ignore
import i18n from "@projectdiction/translations";
import Reading from "./components/Reading";
import {
  BrowserRouter,
  CheckLoginWrapper,
  ToasterWrapper,
  PrimeReactWrapper,
  // @ts-ignore
} from "@projectdiction/common-react";

export default function Root() {
  return (
    <I18nextProvider i18n={i18n}>
      {/* <PrimeReactWrapper> */}
      <BrowserRouter>
        <CheckLoginWrapper>
          <ToasterWrapper>
            <Reading></Reading>
          </ToasterWrapper>
        </CheckLoginWrapper>
      </BrowserRouter>
      {/* </PrimeReactWrapper> */}
    </I18nextProvider>
  );
}
