import {
  Button,
  Card,
  useNavigate,
  NavigationGrid,
  STYLES_CARD,
  ROUTES,
  // @ts-ignore
} from "@projectdiction/common-react";

// @ts-ignore
import { useTranslation } from "react-i18next";

export default function ReadingMainGrid() {
  const { t } = useTranslation();

  return (
    <NavigationGrid
      title={t("reading.titles.reading")}
      data={[
        {
          title: t("reading.lists.titles.reading_lists"),
          route: ROUTES.READING.READING_LIST.HOME,
          description: t("reading.lists.description"),
          label: t("buttons.navigation.go"),
        },
        // {
        //   title: t("reading.lists.titles.reading_history"),
        // },
        // {
        //   title: t("reading.lists.titles.reading_statistics"),
        // },
        // {
        //   title: t("reading.lists.titles.reading_settings"),
        // },
        {
          title: "Translations",
          label: t("buttons.navigation.go"),
        },
        {
          title: "Categories",
          route: ROUTES.READING.CATEGORIES.HOME,
          label: t("buttons.navigation.go"),
        },
      ]}
    />
  );
}
