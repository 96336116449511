import React from "react";
import {
  ReadingListViewItem,
  useParams,
  // @ts-ignore
} from "@projectdiction/common-react";

export default function ReadingListItem() {
  const { id } = useParams();
  return <ReadingListViewItem readingItemId={id} />;
}
