import React from "react";

import {
  useAccessToken,
  useUserPreferences,
  useNavigate,
  useUserDictionaryId,
  Button,
  Card,
  Skeleton,
  ROUTES,
  // @ts-ignore
} from "@projectdiction/common-react";

import {
  getReadingItem,
  getWordList,
  searchText,
  copyReadingItem,
  // @ts-ignore
} from "@projectdiction/api-helper";

// @ts-ignore
import { useTranslation } from "react-i18next";

import ReadingItemSection from "./ReadingItemSection";

function ReadingItemTextCard({ readingItemId, updateParentView, toast }) {
  const [data, setData] = React.useState<any>();

  const token = useAccessToken();

  const { t } = useTranslation();

  const navigate = useNavigate();

  const userPreferences = useUserPreferences();

  const { userDictionaryId } = useUserDictionaryId();

  React.useEffect(() => {
    async function process() {
      let response = await getReadingItem(token, readingItemId);

      const readingItem = response.data.readingItem;

      setData(readingItem);
    }
    try {
      process();
    } catch (error) {
      console.log("error", error);
    }
  }, [readingItemId, token, userPreferences]);

  const rootStyle = { height: "97%" };

  if (!data) {
    return (
      <Card className="w-full h-full" style={rootStyle}>
        <Skeleton width="90%" height="90%" />
      </Card>
    );
  }

  return (
    <Card
      title={data.title}
      subTitle={data.subtitle}
      style={rootStyle}
      pt={{
        root: { className: "w-full h-full" },
        body: { className: "w-full h-full" },
        content: {
          className: "w-full overflow-y-auto",
          style: { height: "calc(100% - 8rem)" },
        },
        title: { className: "w-full flex justify-content-center" },
        subtitle: { className: "w-full flex justify-content-center" },
        footer: { className: "w-full flex justify-content-center" },
      }}
      footer={
        <Button
          icon="pi pi-copy"
          label={t("reading.lists.buttons.copy")}
          onClick={() =>
            copyReadingItem(token, readingItemId, userDictionaryId).then(
              (response) => {
                console.log(response);
                navigate(
                  ROUTES.READING.READING_LIST.VIEW(
                    response.data.readingItem._id
                  )
                );
              }
            )
          }
        />
      }
    >
      <div className="flex flex-wrap h-full">
        {data.text.map(
          (
            text: { headingLevel: 0; heading: string; content: Array<string> },
            index: any
          ) => {
            return (
              <ReadingItemSection
                key={index}
                headingLevel={text.headingLevel}
                heading={text.heading}
                content={text.content}
              />
            );
          }
        )}
      </div>
    </Card>
  );
}

export default ReadingItemTextCard;
