import {
  Button,
  Card,
  NavigationGrid,
  ROUTES,
  // @ts-ignore
} from "@projectdiction/common-react";

// @ts-ignore
import { useTranslation } from "react-i18next";

export default function ReadingListHome() {
  const { t } = useTranslation();

  return (
    <NavigationGrid
      title={t("reading.titles.reading_lists")}
      data={[
        {
          title: t("reading.lists.titles.add_reading_item"),
          route: ROUTES.READING.READING_LIST.ADD,
          description: t(""),
          label: t("reading.lists.buttons.open_form"),
        },
        {
          title: t("reading.lists.titles.view_reading_lists"),
          route: ROUTES.READING.READING_LIST.VIEW(),
          description: t(""),
          label: t("reading.lists.buttons.open_view"),
        },
      ]}
    />
  );
}
