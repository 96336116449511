import React from "react";

import {
  useAccessToken,
  useNavigate,
  useUserPreferences,
  createUseStyles,
  Button,
  Card,
  Editor,
  InputText,
  Messages,
  InputTextarea,
  Toast,
  STYLES_CARD,
  // @ts-ignore
} from "@projectdiction/common-react";

import {
  addReadingItem, // @ts-ignore
} from "@projectdiction/api-helper";

// @ts-ignore
import { useTranslation } from "react-i18next";

const useStyles = createUseStyles({
  root: {
    ...STYLES_CARD,
    "& .p-card-content": {
      flexDirection: "column",
    },
  },
});

function parseHtmlToContentSections(htmlString) {
  const parser = new DOMParser();
  const doc = parser.parseFromString(htmlString, "text/html");

  const sections = [];
  let currentLevel = 0;
  let currentHeading = "";
  let currentParagraphs = []; // Array to store paragraphs

  const elements = doc.body.childNodes;

  for (let i = 0; i < elements.length; i++) {
    const element = elements[i];
    if (element.nodeType === Node.ELEMENT_NODE) {
      // @ts-ignore
      const tagName = element.tagName.toLowerCase();

      if (
        tagName === "h1" ||
        tagName === "h2" ||
        tagName === "h3" ||
        tagName === "h4" ||
        tagName === "h5" ||
        tagName === "h6"
      ) {
        // New heading (end the previous section if any)
        if (currentParagraphs.length > 0) {
          sections.push({
            headingLevel: currentLevel,
            heading: currentHeading,
            content: currentParagraphs, // Assign the array of paragraphs
          });
          currentParagraphs = []; // Reset for the next section
        }
        currentLevel = parseInt(tagName.slice(1));
        currentHeading = element.textContent.trim();
      } else if (tagName === "p") {
        // Paragraph
        if (element.textContent.trim()) {
          // Check if it's not just whitespace
          currentParagraphs.push(element.textContent.trim());
        }
      } else {
        // Ignore other elements for now
        console.warn(`Ignoring unsupported element: ${tagName}`);
      }
    } else if (element.nodeType === Node.TEXT_NODE) {
      // Text content
      if (element.textContent.trim()) {
        // Check if it's not just whitespace
        currentParagraphs.push(element.textContent.trim());
      }
    }
  }

  // Add the last section (or the leading paragraphs with no heading)
  if (currentParagraphs.length > 0) {
    sections.push({
      headingLevel: currentLevel || 0,
      heading: currentHeading || "",
      content: currentParagraphs,
    });
  }

  return sections;
}

export default function ReadingListForm() {
  const classes = useStyles();

  const [title, setTitle] = React.useState("");
  const [subtitle, setSubtitle] = React.useState("");
  const [author, setAuthor] = React.useState("");
  const [source, setSource] = React.useState("");

  const [text, setText] = React.useState("");

  const messagesRef = React.useRef();

  const navigate = useNavigate();

  const { t } = useTranslation();

  const token = useAccessToken();

  const toast = React.useRef();

  const userPreferences = useUserPreferences();

  const saveReadingList = async function () {
    if (title === "") {
      // @ts-ignore
      messagesRef.current.show({
        severity: "error",
        summary: t("reading.lists.toasts.summaries.save_fail"),
        detail: t("reading.lists.toasts.details.title_required"),
      });

      return;
    }
    if (text === "") {
      // @ts-ignore
      messagesRef.current.show({
        severity: "error",
        summary: t("reading.lists.toasts.summaries.save_fail"),
        detail: t("reading.lists.toasts.details.text_required"),
      });

      return;
    }

    const response = await addReadingItem(token, {
      text: parseHtmlToContentSections(text),
      title,
      subtitle,
      author,
      source,
      studyLanguage: userPreferences.languages.studyLanguage,
    });

    // @ts-ignore
    toast.current.show({
      severity: "success",
      summary: t("reading.lists.toasts.summaries.save_success"),
      detail: t("reading.lists.toasts.details.save_success"),
    });

    navigate("/reading/readingList/view/" + response.data.readingItem.id);
  };

  const renderHeader = () => {
    return (
      <span className="ql-formats">
        <select className="ql-header" defaultValue="0">
          <option value="0"></option>
          <option value="1"></option>
          <option value="2"></option>
          <option value="3"></option>
        </select>
      </span>
    );
  };

  const header = renderHeader();

  return (
    <Card className={classes.root}>
      <Card
        title={t("reading.lists.titles.add_reading_item")}
        className="w-8"
        footer={
          <div className="flex align-items-center justify-content-center w-full">
            <Button
              type="button"
              label={t("reading.lists.buttons.save")}
              onClick={saveReadingList}
            />
          </div>
        }
      >
        <div className="field w-12 ">
          <span className="p-float-label">
            <InputText
              id="readingListTitleInput "
              className="w-full"
              type="text"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
            />
            <label htmlFor="readingListTitleInput" className="sr-only">
              {t("reading.lists.texts.title")}
            </label>
          </span>
        </div>

        <div className="field p-float-label w-12">
          <span className="p-float-label">
            <Editor
              value={text}
              onTextChange={(e) => setText(e.htmlValue)}
              headerTemplate={header}
              className="h-17rem"
            ></Editor>
          </span>
        </div>

        <div className="field w-12 mt-5">
          <span className="p-float-label">
            <InputText
              id="readingListSubtitleInput"
              className="w-full"
              type="text"
              value={subtitle}
              onChange={(e) => setSubtitle(e.target.value)}
            />
            <label htmlFor="readingListSubtitleInput" className="sr-only">
              {t("reading.lists.texts.subtitle")}
            </label>
          </span>
        </div>

        <div className="field w-12">
          <span className="p-float-label">
            <InputText
              id="readingListAuthorInput"
              className="w-full"
              type="text"
              value={author}
              onChange={(e) => setAuthor(e.target.value)}
            />
            <label htmlFor="readingListAuthorInput" className="sr-only">
              {t("reading.lists.texts.author")}
            </label>
          </span>
        </div>
        <div className="field w-12">
          <span className="p-float-label">
            <InputText
              id="readingListSourceInput"
              className="w-full"
              type="text"
              value={source}
              onChange={(e) => setSource(e.target.value)}
            />
            <label htmlFor="readingListSourceInput" className="sr-only">
              {t("reading.lists.texts.source")}
            </label>
          </span>
        </div>
        <Messages ref={messagesRef}></Messages>
      </Card>
      <Toast ref={toast} />
    </Card>
  );
}
