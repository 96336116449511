import React from "react";

import {
  useAccessToken,
  useUserPreferences,
  NavigationGrid,
  Button,
  Card,
  Skeleton,
  ROUTES,
  // @ts-ignore
} from "@projectdiction/common-react";

// @ts-ignore
import { getAllReadingItems } from "@projectdiction/api-helper";
// @ts-ignore
import { useTranslation } from "react-i18next";

export default function ReadingListView() {
  const [data, setData] = React.useState<any>();

  const { t } = useTranslation();

  const token = useAccessToken();
  const userPreferences = useUserPreferences();

  React.useEffect(() => {
    if (!userPreferences) return;
    getAllReadingItems(token, userPreferences.languages.studyLanguage)
      .then((response: any) => {
        setData(response.data.readingItems);
      })
      .catch((error: any) => console.log("error", error));
  }, [userPreferences]);

  if (!data) {
    return (
      <div className="flex flex-column w-9 h-full">
        <Skeleton width="100%" height="2rem" />
        <Skeleton width="100%" height="15rem" />
      </div>
    );
  }

  return (
    <NavigationGrid
      title={t("reading.lists.titles.reading_items")}
      data={data.map(({ title, text, _id }) => {
        return {
          title,
          description: text[0].content[0].slice(0, 100),
          route: ROUTES.READING.READING_LIST.VIEW(_id),
          label: t("reading.lists.buttons.read_now"),
        };
      })}
    />
  );
}
