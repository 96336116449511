import {
  useNavigate,
  ReadingCategories,
  Routes,
  Route,
  ROUTES,
  // @ts-ignore
} from "@projectdiction/common-react";

import ReadingListView from "./ReadingList/ReadingListView";
import ReadingListCopyItem from "./ReadingList/ReadingListCopy/ReadingListCopyItem";
import ReadingListHome from "./ReadingList/ReadingListHome";
import ReadingMainGrid from "./ReadingMainGrid";
import ReadingListForm from "./ReadingList/ReadingListForm";
import ReadingListItem from "./ReadingList/ReadingListItem";

export default function ReadingRoutes() {
  const navigate = useNavigate();
  return (
    <Routes>
      <Route exact path={ROUTES.READING.HOME} element={<ReadingMainGrid />} />
      <Route
        exact
        path={ROUTES.READING.READING_LIST.HOME}
        element={<ReadingListHome />}
      />
      <Route
        exact
        path={ROUTES.READING.READING_LIST.ADD}
        element={<ReadingListForm />}
      />
      <Route
        exact
        path={ROUTES.READING.READING_LIST.VIEW()}
        element={<ReadingListView />}
      />
      <Route
        exact
        path={ROUTES.READING.READING_LIST.VIEW(":id")}
        element={<ReadingListItem />}
      />
      <Route
        exact
        path={ROUTES.READING.READING_LIST.COPY(":id")}
        element={<ReadingListCopyItem />}
      />
      <Route
        exact
        path={ROUTES.READING.CATEGORIES.HOME}
        element={
          <ReadingCategories
            onSingleItemClick={(readingItem) =>
              navigate(ROUTES.READING.READING_LIST.COPY(readingItem._id))
            }
          />
        }
      />
    </Routes>
  );
}
